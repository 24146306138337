import React from 'react';

const DislikeButton = ({active, disabled}) => {
    return (
        <>
            {
                (!active)
                ?
                <button className={`${(disabled) && 'disabled-button'}`} style={{backgroundColor: 'transparent', border: 'none'}} disabled={disabled}>
                        <svg className='svgDislike-button' viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" style={{
                        pointerEvents: 'none',
                        display: 'block',
                        width: '60px',
                        height: '60px',
                    }}><g><path d="M17,4h-1H6.57C5.5,4,4.59,4.67,4.38,5.61l-1.34,6C2.77,12.85,3.82,14,5.23,14h4.23l-1.52,4.94C7.62,19.97,8.46,21,9.62,21 c0.58,0,1.14-0.24,1.52-0.65L17,14h4V4H17z M10.4,19.67C10.21,19.88,9.92,20,9.62,20c-0.26,0-0.5-0.11-0.63-0.3 c-0.07-0.1-0.15-0.26-0.09-0.47l1.52-4.94l0.4-1.29H9.46H5.23c-0.41,0-0.8-0.17-1.03-0.46c-0.12-0.15-0.25-0.4-0.18-0.72l1.34-6 C5.46,5.35,5.97,5,6.57,5H16v8.61L10.4,19.67z M20,13h-3V5h3V13z"></path></g></svg>
                </button>
                :
                <button className={`${(disabled) && 'disabled-button'}`} style={{backgroundColor: 'transparent', border: 'none'}} disabled={disabled}>
                        <svg className='svgDislike-button' viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" style={{
                        pointerEvents: 'none',
                        display: 'block',
                        width: '60px',
                        height: '60px',
                    }}><g><path d="M18,4h3v10h-3V4z M5.23,14h4.23l-1.52,4.94C7.62,19.97,8.46,21,9.62,21c0.58,0,1.14-0.24,1.52-0.65L17,14V4H6.57 C5.5,4,4.59,4.67,4.38,5.61l-1.34,6C2.77,12.85,3.82,14,5.23,14z"></path></g></svg>
                </button>
            }
        </>
    )
}

export default DislikeButton;
