import React from 'react'

const NoBiographyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="602"
      height="535.284"
      data-name="Layer 1"
      viewBox="0 0 602 535.284"
    >
      <path
        fill="#f0f0f0"
        d="M87.511 429.868l-18.301-11.66a273.135 273.135 0 01-.787-30.776l9.494 2.426-9.37-5.97c.517-12.69 1.663-21.06 1.663-21.06s37.003 24.036 60.603 56.615l-4.024 27.554 9.597-19.27a110.505 110.505 0 016.603 12.218c19.444 42.478 21.334 83.264 4.22 91.098s-46.75-20.252-66.194-62.73c-6.028-13.169-9.355-28.31-11.079-42.936z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M49.918 470.87l-21.694-.521a273.14 273.14 0 01-16.582-25.94l9.381-2.83-11.107-.267C3.8 430.182.454 422.423.454 422.423s44.1 1.449 81.144 17.14l10.797 25.667-1.744-21.456a110.507 110.507 0 0111.967 7.045c38.602 26.313 61.302 60.25 50.701 75.802s-50.488 6.83-89.09-19.483c-11.967-8.158-22.642-19.4-31.678-31.028z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M359.102 150.255l-17-2-6 52-15.319 71.808a10.997 10.997 0 1011.933 1.505l15.386-42.313z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M383.797 520.968L369.791 520.968 363.127 466.943 383.799 466.944 383.797 520.968z"
      ></path>
      <path
        fill="#2f2e41"
        d="M387.369 534.545l-45.162-.001v-.572a17.58 17.58 0 0117.578-17.578h.001l27.584.001z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M491.287 513.329L477.535 515.984 460.747 464.204 481.044 460.285 491.287 513.329z"
      ></path>
      <path
        fill="#2f2e41"
        d="M497.37 525.982l-44.344 8.563-.108-.56a17.58 17.58 0 0113.926-20.593h.001l27.083-5.23zM361.836 65.039l8.138 15.613 60.95-13.443-6.676-30.273C418.963 12.975 397.775-4.33 373.814.955S338.672 29.66 343.957 53.62l9.822 30.603 9.937-2.192zM340.102 237.255s-6 30 2 74 10 63 10 63-6 7-2 11 0 12 0 12l9 97 31-5-4-91s4-8 2-10 2-14 2-14l5-95 31 104s-3 4 0 7 9 18 9 18l20 70 32-6-18-80s2-5-1-8a7.833 7.833 0 01-2-7s3-3-1-7-6-20-6-20-3-107-13-113-106 0-106 0z"
      ></path>
      <circle cx="385.318" cy="43.794" r="28" fill="#ffb6b6"></circle>
      <path
        fill="#e6e6e6"
        d="M424.778 79.636s20.324-2.381 26.324 3.619l-6 121s4 3 2 10a28.569 28.569 0 000 14s6 7 3 10-111 1-111 1-4-7 2-11 3-15 3-15-2-3 3-8 9-46 9-46l-13-50s13.086-14.77 28.043-15.885z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M358.594 109.943l-15.492-.688s-9 18-10 34l-5.508 59.688 35.508 5.312z"
      ></path>
      <path
        fill="#2f2e41"
        d="M361.33 45.182l-10.402-13.381a10.91 10.91 0 018.282-12.97l39.293-8.666a10.912 10.912 0 0112.97 8.282l.021.097-2.814 16.159-.494-.088c-24.384-4.348-31.33-2.819-46.459 10.225z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M465.977 258.995l-1.875-78.74-2-56-24 3 12.889 133.846a11 11 0 1014.986-2.106z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M433.594 84.943l17.508-1.688s3 0 6 10 19.492 115.688 19.492 115.688l-36.492 3.312z"
      ></path>
      <path
        fill="#3f3d56"
        d="M324.822 209.884h-2.34v-64.1a37.1 37.1 0 00-37.1-37.1H149.578a37.1 37.1 0 00-37.1 37.1v351.661a37.1 37.1 0 0037.1 37.1h135.806a37.1 37.1 0 0037.1-37.1V255.513h2.34z"
      ></path>
      <path
        fill="#fff"
        d="M286.88 118.336h-17.728a13.163 13.163 0 01-12.187 18.134h-77.801a13.163 13.163 0 01-12.187-18.134h-16.558a27.706 27.706 0 00-27.705 27.705v351.146a27.706 27.706 0 0027.705 27.706h136.46a27.706 27.706 0 0027.706-27.706V146.042a27.706 27.706 0 00-27.705-27.706z"
      ></path>
      <path
        fill="#2f2e41"
        d="M196.744 245.95l3.773 13.071 48.22-.85-.423-23.95c-.334-18.956-13.732-35.306-32.688-34.972s-31.01 16.36-30.675 35.317l2.754 24.681 7.862-.139z"
      ></path>
      <circle cx="217.781" cy="233.455" r="21.635" fill="#ffb6b6"></circle>
      <path
        fill="#e4e4e4"
        d="M260.844 267.194l-.49 9.1a57.929 57.929 0 01-80.29 3.11c-.5-.44-.99-.9-1.47-1.37 3-2.98 11.28-10.34 20.47-11.02l41.44-2.61s15.7-1.84 20.34 2.79z"
      ></path>
      <path
        fill="#2f2e41"
        d="M241.465 218.264a8.391 8.391 0 00-1.49-4.63c-.1-.14-.2-.27-.3-.4a8.43 8.43 0 00-6.76-3.23l-15.55.28-15.54.27a8.42 8.42 0 00-8.25 8.55l3.7 7.45 2.13 4.28.35-.2c13.45-7.56 18.95-7.66 36.75-.63l.36.14 1.41-3.61 3.2-8.19z"
      ></path>
      <path
        fill="#e4e4e4"
        d="M264.344 271.704a58.619 58.619 0 01-16.06 14.18l-.97-17.38 13.53-1.31s1.62 0 3.5 4.51z"
      ></path>
      <path
        fill="#3f3d56"
        d="M218.594 295.054a58.818 58.818 0 01-39.193-14.903c-.51-.448-1.014-.92-1.506-1.403a58.399 58.399 0 01-18.3-42.694 59 59 0 01118 0 58.365 58.365 0 01-12.462 36.265 58.956 58.956 0 01-46.539 22.735zm0-116a57.065 57.065 0 00-57 57 56.426 56.426 0 0017.69 41.255c.477.468.955.916 1.441 1.344a57.068 57.068 0 0082.831-7.565 56.38 56.38 0 0012.038-35.034 57.065 57.065 0 00-57-57z"
      ></path>
      <path
        fill="#6c63ff"
        d="M271.634 376.851H167.696a7.892 7.892 0 110-15.784h103.938a7.892 7.892 0 110 15.784zM271.634 401.851H167.696a7.892 7.892 0 110-15.784h103.938a7.892 7.892 0 110 15.784zM246.331 351.548H193a7.892 7.892 0 110-15.785h53.332a7.892 7.892 0 010 15.785z"
      ></path>
      <path
        fill="#cacaca"
        d="M601 535.284H1a1 1 0 010-2h600a1 1 0 010 2z"
      ></path>
    </svg>
  )
}

export default NoBiographyIcon;