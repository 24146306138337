import React from 'react';

const NoDataIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="590.724"
      height="659.362"
      data-name="Layer 1"
      viewBox="0 0 590.724 659.362"
    >
      <path
        fill="#e6e6e6"
        d="M867.573 741.807a22.728 22.728 0 01-21.947-3.866c-7.687-6.452-10.1-17.08-12.058-26.924l-5.8-29.112 12.143 8.362c8.733 6.013 17.662 12.22 23.709 20.93s8.686 20.6 3.828 30.023"
        data-name="Path 461"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M866.535 777.053c1.229-8.953 2.493-18.02 1.63-27.069-.765-8.036-3.216-15.885-8.208-22.32a37.132 37.132 0 00-9.527-8.634c-.953-.6-1.83.91-.881 1.507a35.3 35.3 0 0113.963 16.847c3.04 7.732 3.528 16.161 3 24.374-.317 4.967-.988 9.9-1.665 14.83a.9.9 0 00.61 1.074.878.878 0 001.074-.61z"
        data-name="Path 462"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#fff"
        d="M822.523 628.353H314.49V120.32h508.034z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#e5e5e5"
        d="M822.523 628.353H314.49V120.32h508.034zm-500.528-7.506h493.022V127.825H321.995z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path fill="#e5e5e5" d="M207.885 79.482H427.885V86.335H207.885z"></path>
      <path
        fill="#e5e5e5"
        d="M207.885 97.3H427.885V104.15299999999999H207.885z"
      ></path>
      <path
        fill="#e5e5e5"
        d="M207.885 115.118H427.885V121.97099999999999H207.885z"
      ></path>
      <path fill="#e5e5e5" d="M207.885 232.483H427.885V239.336H207.885z"></path>
      <path fill="#e5e5e5" d="M207.885 250.301H427.885V257.154H207.885z"></path>
      <path
        fill="#e5e5e5"
        d="M207.885 268.119H427.885V274.97200000000004H207.885z"
      ></path>
      <path fill="#e5e5e5" d="M207.885 385.483H427.885V392.336H207.885z"></path>
      <path fill="#e5e5e5" d="M207.885 403.301H427.885V410.154H207.885z"></path>
      <path
        fill="#e5e5e5"
        d="M207.885 421.119H427.885V427.97200000000004H207.885z"
      ></path>
      <path
        fill="#3f3d56"
        d="M424.452 254.403a73.485 73.485 0 00-11.725-4.934l-.515-5.704-20.911-2.253-2.658 7.29-7.504 2.817a3.653 3.653 0 00-.762.396h-.008a3.718 3.718 0 00-1.586 2.475 3.801 3.801 0 00.055 1.492l.397 1.626a37.58 37.58 0 001.745.976l.373.19c.857.444 1.73.865 2.626 1.253.182.08.38.16.563.238.016.008.024.008.04.016.42.175.84.341 1.269.5a41.691 41.691 0 004.3 1.38 44.214 44.214 0 0011.399 1.492c1.404 0 2.792-.064 4.165-.199a44.039 44.039 0 009.234-1.864c.007 0 .015-.008.023-.008a43.341 43.341 0 005.545-2.197c.008-.008.024-.008.032-.016.405-.19.793-.397 1.19-.595l.19-.095c.317-.167.635-.333.952-.508.794-.436 1.571-.896 2.34-1.388.214-.127.42-.262.635-.405.301-.198.603-.404.896-.618a34.409 34.409 0 00-2.3-1.357z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#3f3d56"
        d="M385.208 257.917l-1.309-5.91h-3.419a.818.818 0 00-.103.008h-.008a7.41 7.41 0 00-3.443 1.19 7.625 7.625 0 00-1.84 1.65c1.015.77 2.07 1.484 3.157 2.166l.048.024c.31.19.627.38.944.563a37.58 37.58 0 001.745.976l.373.19c.857.444 1.73.865 2.626 1.253.182.08.38.16.563.238.016.008.024.008.04.016.42.175.84.341 1.269.5z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <circle cx="96.297" cy="102.527" r="15.06" fill="#ffb8b8"></circle>
      <path
        fill="#2f2e41"
        d="M390.824 215.51c1.56 1.846 4.198 2.366 6.631 2.58a62.454 62.454 0 0016.96-1.385c.345 3.341-.595 6.944 1.374 9.693a44.998 44.998 0 001.598-10.201 9.764 9.764 0 00-.565-4.37 4.018 4.018 0 00-3.423-2.523 8.58 8.58 0 013.827-1.636l-4.782-2.403 1.228-1.26-8.657-.53 2.507-1.593a54.373 54.373 0 00-11.391-.36 9.778 9.778 0 00-5.038 1.4c-1.43 1.023-2.256 3.054-1.368 4.555a6.613 6.613 0 00-5.04 3.999 10.676 10.676 0 00-.382 5.132 36.375 36.375 0 001.907 8.068z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#3f3d56"
        d="M443.284 206.52a44.193 44.193 0 10-76.369 41.981c.397.5.81 1 1.23 1.484.032.031.055.063.087.095a42.863 42.863 0 003.292 3.395c.302.278.62.563.936.833a31.52 31.52 0 001.865 1.547c.253.198.5.396.761.587 1.015.77 2.07 1.483 3.157 2.166l.048.023c.31.19.627.381.944.564a37.58 37.58 0 001.745.975l.373.19c.857.445 1.73.865 2.626 1.254.182.08.38.159.563.238.016.008.024.008.04.016.42.175.84.341 1.269.5a41.693 41.693 0 004.3 1.38 44.214 44.214 0 0011.399 1.491c1.404 0 2.792-.063 4.165-.198a44.039 44.039 0 009.234-1.864c.007 0 .015-.008.023-.008a43.34 43.34 0 005.545-2.197c.008-.008.024-.008.032-.016.405-.19.793-.397 1.19-.595l.19-.096a41.15 41.15 0 003.292-1.896c.214-.126.42-.261.635-.404.302-.198.603-.405.896-.619.389-.27.778-.547 1.159-.833.119-.087.246-.174.364-.27a44.177 44.177 0 0015.01-49.722zm-16.468 47.796c-.262.206-.531.404-.801.587a13.08 13.08 0 01-.794.563c-.23.167-.46.325-.698.476l-.071.048c-.175.119-.349.23-.524.34q-.987.62-1.999 1.19l-.214.12a50.01 50.01 0 01-.904.484c-.04.023-.088.04-.127.063l-.048.024c-.38.198-.77.389-1.158.571a38.911 38.911 0 01-4.625 1.872c-.206.072-.412.143-.618.206a41.61 41.61 0 01-8.742 1.777 43.308 43.308 0 01-3.943.183 41.21 41.21 0 01-10.78-1.412 39.172 39.172 0 01-5.292-1.793c-.087-.04-.183-.071-.27-.111-.095-.04-.182-.071-.27-.111a4.304 4.304 0 01-.277-.127 37.41 37.41 0 01-2.205-1.055l-.35-.182c-.88-.46-1.745-.952-2.586-1.476-.246-.15-.5-.31-.737-.476-.064-.04-.12-.08-.183-.119a39.488 39.488 0 01-1.674-1.166c-.372-.278-.745-.555-1.11-.841-.61-.46-1.198-.952-1.77-1.46-.3-.254-.594-.531-.88-.793a40.306 40.306 0 01-3.189-3.3c-.333-.38-.658-.762-.968-1.158a3.022 3.022 0 01-.198-.246 41.773 41.773 0 1158.005 7.322z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#fff"
        d="M443.285 359.521a44.193 44.193 0 10-76.37 41.98c.397.5.81 1 1.23 1.484.032.032.055.063.087.095a42.945 42.945 0 003.292 3.395c.302.278.62.564.936.833a31.63 31.63 0 001.865 1.547 43.45 43.45 0 003.918 2.753l.048.024c.88.547 1.777 1.055 2.69 1.539l.372.19c.857.444 1.73.865 2.626 1.253.182.08.38.16.563.238.016.008.024.008.04.016a42.929 42.929 0 005.569 1.88 44.211 44.211 0 0011.399 1.492c1.404 0 2.792-.064 4.165-.199a44.034 44.034 0 009.233-1.864c.009 0 .016-.008.024-.008a43.317 43.317 0 005.545-2.197c.008-.008.024-.008.032-.016.404-.19.793-.397 1.19-.595l.19-.095c.318-.167.635-.333.952-.508.794-.436 1.571-.896 2.34-1.388.215-.127.42-.262.635-.405.698-.46 1.388-.952 2.055-1.451.119-.088.246-.175.365-.27a44.18 44.18 0 0015.009-49.723z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <circle
        cx="111.495"
        cy="265.321"
        r="6.623"
        fill="#2f2e41"
        data-name="Ellipse 188"
      ></circle>
      <path
        fill="#2f2e41"
        d="M417.979 379.2a6.624 6.624 0 015.83 9.766 6.622 6.622 0 10-11-7.276 6.607 6.607 0 015.17-2.49z"
        data-name="Path 969"
        transform="translate(-304.638 -120.319)"
      ></path>
      <circle
        cx="99.119"
        cy="245.851"
        r="19.456"
        fill="#2f2e41"
        data-name="Ellipse 189"
      ></circle>
      <path
        fill="#2f2e41"
        d="M388.487 354.099a19.453 19.453 0 0126.963-2.04c-.158-.152-.317-.305-.481-.451a19.456 19.456 0 00-25.964 28.982l.11.1c.166.146.336.285.505.425a19.453 19.453 0 01-1.132-27.017z"
        data-name="Path 970"
        transform="translate(-304.638 -120.319)"
      ></path>
      <circle
        cx="98.954"
        cy="252.611"
        r="12.531"
        fill="#a0616a"
        data-name="Ellipse 190"
      ></circle>
      <path
        d="M426.015 405.579v4.204c-.77.492-1.547.952-2.34 1.389-.318.174-.635.34-.952.507l-.19.095c-.397.199-.786.405-1.19.595-.008.008-.025.008-.032.016a43.317 43.317 0 01-5.546 2.198c-.007 0-.015.008-.023.008a44.034 44.034 0 01-9.234 1.864c-1.372.135-2.76.198-4.165.198a44.211 44.211 0 01-11.399-1.491 42.93 42.93 0 01-5.569-1.88c-.016-.008-.024-.008-.04-.016-.182-.08-.38-.159-.563-.238a42.04 42.04 0 01-2.626-1.253l-.372-.19a45.062 45.062 0 01-2.69-1.54l-.047-.024a43.45 43.45 0 01-3.92-2.752 31.634 31.634 0 01-1.863-1.547 31.79 31.79 0 01-.936-.833 42.947 42.947 0 01-3.293-3.395 16.049 16.049 0 01.603-2.436l.008-.008a3.562 3.562 0 01.167-.396 3.164 3.164 0 01.15-.334c1.42-2.657 3.99-3.315 7.18-3.458 1.8-.08 3.792.008 5.894-.016 1.11-.008 2.26-.048 3.411-.15 7.259-.66 5.942-4.943 6.267-7.251.325-2.237 2.34-.302 2.475-.175l.008.008a25.6 25.6 0 0013.168 2.308c.778-.095 1.555-.15 2.333-.174 2.046-.024 1.602.563.896 1.047a8.872 8.872 0 01-1.07.603s-.326 1.983-.66 4.95c-.316 2.84 5.125 4.173 5.577 4.276a.048.048 0 00.032.008 10.926 10.926 0 016.037.158h.008a7.154 7.154 0 014.506 5.125z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#2f2e41"
        d="M385.454 410.68c0 .587-.015 1.15-.04 1.681-.007.318-.023.627-.039.92-.016-.007-.024-.007-.04-.015-.182-.08-.38-.159-.563-.238a42.04 42.04 0 01-2.626-1.254l-.372-.19a45.062 45.062 0 01-2.69-1.539c.024-.278.064-.555.103-.833.087-.61.16-1.23.207-1.84a25.53 25.53 0 00-2.261-12.502v-.008c-.159-.318-.254-.492-.254-.492s2.308-1.983 5.6 0a1.981 1.981 0 01.548.476c1.983 2.34 2.522 10.21 2.427 15.834zM421.477 409.6c-.032.89-.08 1.817-.135 2.761v.008c-.007.008-.024.008-.031.016a43.317 43.317 0 01-5.546 2.198c-.007 0-.015.008-.023.008l-.087-2.23-.009-.293-.42-10.852a1.82 1.82 0 011.817-1.904h4.022c.198 0 .34.405.436 1.134v.008a68.744 68.744 0 01-.024 9.147z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#2f2e41"
        d="M392.874 357.267V369.8h2.902l3.693-3.957-.494 3.957h12.827l-.793-3.957 1.586 3.957h2.046v-12.534z"
        data-name="Path 975"
        transform="translate(-304.638 -120.319)"
      ></path>
      <ellipse
        cx="86.258"
        cy="249.974"
        fill="#a0616a"
        data-name="Ellipse 191"
        rx="0.989"
        ry="1.813"
      ></ellipse>
      <ellipse
        cx="111.65"
        cy="249.974"
        fill="#a0616a"
        data-name="Ellipse 192"
        rx="0.989"
        ry="1.813"
      ></ellipse>
      <path
        d="M428.704 407.927c-.666.5-1.357.992-2.055 1.452-.214.143-.42.277-.634.404-.77.492-1.547.952-2.34 1.389-.318.174-.635.34-.952.507v-8.742s1.269 1.016 3.292 2.705c.254.215.515.437.793.675a205.01 205.01 0 011.896 1.61z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#3f3d56"
        d="M443.285 359.521a44.193 44.193 0 10-76.37 41.98c.397.5.81 1 1.23 1.484.032.032.055.063.087.095a42.945 42.945 0 003.292 3.395c.302.278.62.564.936.833a31.63 31.63 0 001.865 1.547 43.45 43.45 0 003.918 2.753l.048.024c.88.547 1.777 1.055 2.69 1.539l.372.19c.857.444 1.73.865 2.626 1.253.182.08.38.16.563.238.016.008.024.008.04.016a42.929 42.929 0 005.569 1.88 44.211 44.211 0 0011.399 1.492c1.404 0 2.792-.064 4.165-.199a44.034 44.034 0 009.233-1.864c.009 0 .016-.008.024-.008a43.317 43.317 0 005.545-2.197c.008-.008.024-.008.032-.016.404-.19.793-.397 1.19-.595l.19-.095c.318-.167.635-.333.952-.508.794-.436 1.571-.896 2.34-1.388.215-.127.42-.262.635-.405.698-.46 1.388-.952 2.055-1.451.119-.088.246-.175.365-.27a44.18 44.18 0 0015.009-49.723zm-16.47 47.795c-.26.206-.53.405-.8.587-.262.199-.524.389-.793.563-.23.167-.46.326-.699.476-.198.135-.396.262-.595.39q-.987.618-1.998 1.189l-.215.119c-.301.167-.603.325-.904.484-.04.024-.088.04-.127.063l-.048.024c-.38.199-.769.389-1.158.571a38.893 38.893 0 01-4.625 1.873c-.206.07-.412.142-.618.206a41.612 41.612 0 01-8.742 1.777 43.309 43.309 0 01-3.943.182 41.213 41.213 0 01-10.78-1.412 39.181 39.181 0 01-5.292-1.793c-.182-.071-.365-.15-.54-.222a4.4 4.4 0 01-.277-.127 37.497 37.497 0 01-2.205-1.055l-.35-.182c-.88-.46-1.745-.952-2.586-1.476-.309-.19-.618-.388-.92-.595a42.627 42.627 0 01-2.784-2.007c-.611-.46-1.198-.952-1.77-1.46-.3-.253-.594-.53-.88-.793a40.363 40.363 0 01-3.189-3.3c-.333-.38-.658-.761-.968-1.158a3.015 3.015 0 01-.198-.246 41.773 41.773 0 1158.005 7.322z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <circle cx="97.161" cy="409.276" r="17.315" fill="#2f2e41"></circle>
      <ellipse
        cx="384.485"
        cy="516.46"
        fill="#2f2e41"
        rx="7.165"
        ry="5.373"
        transform="rotate(-45 86.928 824.031)"
      ></ellipse>
      <ellipse
        cx="413.257"
        cy="511.947"
        fill="#2f2e41"
        rx="5.373"
        ry="7.165"
        transform="rotate(-66.87 169.822 682.486)"
      ></ellipse>
      <path
        fill="#cacaca"
        d="M422.51 563.181l-2.04-3.21a8.007 8.007 0 00-14.64 6.49l.58 2.08.66 2.36a45.21 45.21 0 007.88-1.72.034.034 0 00.02-.01 42.148 42.148 0 005.55-2.2c.01-.01.02-.01.03-.02.4-.19.79-.39 1.19-.59a1.735 1.735 0 00.19-.1c.32-.16.64-.33.95-.5q.48-.255.93-.54z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <circle cx="97.711" cy="413.41" r="14.695" fill="#ffb6b6"></circle>
      <path
        fill="#cacaca"
        d="M423.57 562.551a31.785 31.785 0 00-5.97-7.24 8.729 8.729 0 00-5.82-2.22h-20.7a8.724 8.724 0 00-8.71 9.28 8.937 8.937 0 00.36 1.98 9.397 9.397 0 00.55 1.36l1.02 2.04c.08.03.16.07.24.1.02.01.03.01.04.02.42.17.84.34 1.27.5a41.35 41.35 0 004.3 1.38 43.328 43.328 0 007.16 1.29 44.27 44.27 0 008.41 0c.45-.04.9-.09 1.35-.14a45.21 45.21 0 007.88-1.72.034.034 0 00.02-.01 42.148 42.148 0 005.55-2.2c.01-.01.02-.01.03-.02.4-.19.79-.39 1.19-.59a1.735 1.735 0 00.19-.1c.32-.16.64-.33.95-.5q.48-.255.93-.54c.32-.17.62-.36.93-.55-.38-.75-.77-1.45-1.17-2.12z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#cacaca"
        d="M394.83 555.771a8 8 0 00-10.62 3.82l-1.84 2.78-.88 1.33-1.34 2.02c.28.15.55.3.83.45l.37.19c.86.44 1.73.86 2.63 1.25.1.05.21.09.32.14.08.03.16.07.24.1.02.01.03.01.04.02.42.17.84.34 1.27.5a41.35 41.35 0 004.3 1.38 43.328 43.328 0 007.16 1.29l.7-2.37.68-2.26a8.013 8.013 0 00-3.86-10.64z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#2f2e41"
        d="M386.908 524.486a19.986 19.986 0 0011.422 3.53 12.248 12.248 0 01-4.854 1.997 40.3 40.3 0 0016.46.092 10.654 10.654 0 003.447-1.183 4.361 4.361 0 002.127-2.845c.361-2.063-1.247-3.938-2.917-5.201a21.519 21.519 0 00-18.083-3.614 9.903 9.903 0 00-5.356 3.027 4.879 4.879 0 00-.45 5.794z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#3f3d56"
        d="M443.29 512.521a44.194 44.194 0 10-76.37 41.98c.39.5.81 1 1.23 1.48a.825.825 0 00.08.1 42.154 42.154 0 003.3 3.39c.3.28.61.57.93.84.6.54 1.22 1.05 1.87 1.54.25.2.5.4.76.59 1.01.77 2.07 1.48 3.15 2.17a.075.075 0 01.05.02c.31.19.63.38.95.56.3.18.61.36.91.53.28.15.55.3.83.45l.37.19c.86.44 1.73.86 2.63 1.25.1.05.21.09.32.14.08.03.16.07.24.1.02.01.03.01.04.02.42.17.84.34 1.27.5a41.35 41.35 0 004.3 1.38 43.328 43.328 0 007.16 1.29 44.27 44.27 0 008.41 0c.45-.04.9-.09 1.35-.14a45.21 45.21 0 007.88-1.72.034.034 0 00.02-.01 42.148 42.148 0 005.55-2.2c.01-.01.02-.01.03-.02.4-.19.79-.39 1.19-.59a1.735 1.735 0 00.19-.1c.32-.16.64-.33.95-.5q.48-.255.93-.54c.32-.17.62-.36.93-.55l.48-.3c.22-.13.42-.26.64-.41.3-.19.6-.4.89-.61.39-.27.78-.55 1.16-.84a4.34 4.34 0 00.37-.27 44.221 44.221 0 0015.01-49.72zm-16.47 47.79c-.26.21-.53.41-.8.59-.27.2-.53.39-.8.57-.23.16-.46.32-.7.47a.438.438 0 01-.07.05c-.17.12-.35.23-.52.34-.12.07-.24.15-.36.22-.35.22-.7.43-1.06.63-.19.12-.39.23-.58.34l-.21.12c-.31.17-.61.32-.91.48-.04.03-.09.04-.12.07a.078.078 0 00-.05.02 25 25 0 01-1.16.57 38.611 38.611 0 01-4.63 1.87c-.2.07-.41.15-.61.21a41.848 41.848 0 01-7.83 1.68c-.3.04-.61.07-.92.1a42.784 42.784 0 01-7.48.03 41.231 41.231 0 01-7.24-1.26 38.302 38.302 0 01-5.29-1.8c-.09-.04-.18-.07-.27-.11-.1-.04-.18-.07-.27-.11-.1-.04-.18-.08-.28-.13-.65-.28-1.3-.59-1.93-.91-.09-.05-.18-.09-.27-.14l-.35-.18c-.21-.11-.42-.22-.62-.33-.67-.36-1.33-.75-1.97-1.15-.24-.15-.5-.31-.74-.47l-.18-.12c-.56-.38-1.12-.76-1.67-1.17-.37-.28-.75-.55-1.11-.84-.61-.46-1.2-.95-1.77-1.46-.3-.25-.6-.53-.88-.79a39.675 39.675 0 01-3.19-3.3c-.33-.38-.66-.76-.97-1.16-.07-.08-.13-.16-.2-.25a41.778 41.778 0 1158.01 7.32z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#3f3d56"
        d="M475.484 296.608a103.863 103.863 0 105.889 153.39l127.4 107.499a9.571 9.571 0 0012.358-14.618l-.014-.012-127.4-107.5a103.869 103.869 0 00-18.233-138.759zm-8.213 128.408a77.815 77.815 0 11-9.29-109.654 77.815 77.815 0 019.29 109.654z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        d="M357.617 434.306a77.819 77.819 0 01-13.204-104.609q-2.232 2.248-4.315 4.708A77.815 77.815 0 10459.04 434.77q2.079-2.463 3.915-5.045a77.819 77.819 0 01-105.339 4.582z"
        opacity="0.3"
        transform="translate(-304.638 -120.319)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#ffb6b6"
        d="M732.323 561.516a10.743 10.743 0 00-3.07-16.185l-23.221-95.049-16.94 5.408 25.075 94.4a10.8 10.8 0 0018.156 11.426z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#3f3d56"
        d="M415.682 380.399L403.302 391.079 385.982 406.01 378.332 366.87 377.532 362.8 376.902 359.57 394.452 329 409.082 329.839 410.082 329.899 410.102 330.12 410.332 332.17 413.332 359.149 413.402 359.8 415.682 380.399z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M334.763 646.074L322.504 646.073 316.671 598.785 334.766 598.786 334.763 646.074z"
      ></path>
      <path
        fill="#2f2e41"
        d="M642.528 778.277l-39.53-.002v-.5a15.387 15.387 0 0115.386-15.386l24.145.001z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#ffb6b6"
        d="M462.763 647.074L450.504 647.073 444.671 599.785 462.766 599.786 462.763 647.074z"
      ></path>
      <path
        fill="#2f2e41"
        d="M770.528 779.277l-39.53-.002v-.5a15.387 15.387 0 0115.386-15.386l24.145.001zM777.2 748.569l-33 5-48.01-162.48-50.99 163.48-37-5 48-190v-15l6.5-22.5-.68-6.09-4.73-42.59-2.05-18.47-.133-1.194a4.056 4.056 0 013.193-4.416l15.4-3.24h25.17l14.85 4.09 1.02.28.46.13-.23 1.92-3.48 29.18-.19 1.6-3.36 28.13-.74 6.17c29 21 30 42 30 42z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <circle cx="378.605" cy="288.537" r="24.561" fill="#ffb6b6"></circle>
      <path
        fill="#3f3d56"
        d="M660.7 515.069H709.7V522.069H660.7z"
        transform="rotate(-7.451 -391.073 2797.835)"
      ></path>
      <path
        fill="#ffb6b6"
        d="M595.282 520.37a10.527 10.527 0 011.317 1.008l48.056-12.25 3.279-11.594 18.143 2.66-3.81 21.089a8 8 0 01-7.034 6.534l-56.111 5.909a10.497 10.497 0 11-3.84-13.355z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#3f3d56"
        d="M669.2 455.569l-8.5-6.5c-2.298-.872-5.447 1.605-6 4l-12 49 25 18 3.5-5.5z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        d="M717.97 479.468a67.68 67.68 0 01-6.48 2.2c-.7.21-1.42.41-2.13.6a66.019 66.019 0 01-14.34 2.32 45.14 45.14 0 01-6.26-.17 35.243 35.243 0 01-6.59-1.3l-.22-.06a28.906 28.906 0 01-11.95-6.79 23.56 23.56 0 01-2.11-2.35 21.239 21.239 0 01-4.56-16.53 14.156 14.156 0 01.34-1.57 25.108 25.108 0 011.49-4.1c.27-.62.57-1.23.89-1.83l11.3-3.82h21.52l14.85 4.09 1 .06.02.22.46.13-.23 1.92z"
        opacity="0.2"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#2f2e41"
        d="M664.423 388.522c-4.593-.563-9.634-.165-13.214 2.767s-4.736 9.028-1.346 12.179c3 2.789 7.693 2.133 11.713 2.926a15.854 15.854 0 0112.383 15.156c-.06 10.175-9.824 17.88-11.578 27.901-1.197 6.84 1.688 14.039 6.675 18.87s11.843 7.443 18.751 8.15 13.893-.373 20.606-2.148c6.741-1.782 13.378-4.31 19.034-8.388s10.293-9.825 12.11-16.558.512-14.45-4.168-19.62c-5.36-5.918-14.275-7.954-18.91-14.455-5.197-7.288-3.274-17.666-7.48-25.567-2.807-5.275-8.124-8.874-13.791-10.769s-11.72-2.27-17.692-2.453c-3.14-.097-6.376-.129-9.3 1.016s-5.497 3.753-5.649 6.89 1.856 4.103 1.856 4.103z"
        transform="translate(-304.638 -120.319)"
      ></path>
      <path
        fill="#cacaca"
        d="M894.362 779.681h-381a1 1 0 010-2h381a1 1 0 010 2z"
        transform="translate(-304.638 -120.319)"
      ></path>
    </svg>
  )
}

export default NoDataIcon;