import React from 'react';

const NoReviewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="595.246"
      height="563.116"
      data-name="Layer 1"
      viewBox="0 0 595.246 563.116"
    >
      <path
        fill="#6c63ff"
        d="M768.689 610.58a6.823 6.823 0 006.845-1.254l10.832-9.447a6.824 6.824 0 00.657-9.618l-78.737-90.28a6.824 6.824 0 00-9.618-.658l-10.832 9.447a6.824 6.824 0 00-.657 9.618l78.737 90.281a6.776 6.776 0 002.773 1.912zM660.473 511.236a6.85 6.85 0 002.088-.328 6.77 6.77 0 003.984-3.366l6.586-12.775a6.823 6.823 0 00-2.935-9.182L563.723 430.69a6.824 6.824 0 00-9.183 2.935l-6.586 12.774a6.823 6.823 0 002.935 9.183l106.474 54.895a6.773 6.773 0 003.11.76z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#2f2e41"
        d="M662.393 514.327a6.837 6.837 0 006.106-3.765l6.448-12.845a6.804 6.804 0 00.528-4.684 6.886 6.886 0 00-.15-.52c-.13-.392-.271-1.004-.435-1.713-.523-2.26-1.173-5.072-2.498-5.737l-46.785-28.357a6.813 6.813 0 00-9.164 2.976l-.02.04-11.801 24.324a6.823 6.823 0 003.037 9.145l51.73 20.423a6.677 6.677 0 003.004.713z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <circle cx="379.437" cy="230.723" r="52.118" fill="#6c63ff"></circle>
      <path
        fill="#6c63ff"
        d="M691.973 730.11h14.373a6.824 6.824 0 006.816-6.816v-121.74a6.824 6.824 0 00-6.816-6.816h-14.373a6.824 6.824 0 00-6.817 6.816v121.74a6.824 6.824 0 006.817 6.817z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#6c63ff"
        d="M630.045 673.45a6.813 6.813 0 005.555-2.849l69.674-97.445a6.824 6.824 0 00-1.58-9.51l-11.692-8.36a6.823 6.823 0 00-9.51 1.58l-69.674 97.445a6.824 6.824 0 001.58 9.51l11.692 8.36a6.78 6.78 0 003.955 1.27z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#6c63ff"
        d="M648.53 728.302a9.384 9.384 0 004.117-1.03l12.866-6.406c3.365-1.676 5.219-4.813 4.133-6.994l-33.16-66.608c-1.087-2.18-4.707-2.592-8.072-.918l-12.867 6.406a9.074 9.074 0 00-3.688 3.207 3.852 3.852 0 00-.445 3.787l33.161 66.608c.636 1.277 2.141 1.948 3.955 1.948z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#2f2e41"
        d="M711.082 551.573a6.837 6.837 0 007.139-1.544l19.499-18.71a6.816 6.816 0 00.078-9.639l-.005-.005-41.444-35.83c-1.012-1.031-3.888-.782-6.198-.584-.725.062-1.351.116-1.765.113a6.806 6.806 0 00-.54.018 6.805 6.805 0 00-4.295 1.945l-10.234 10.09a6.825 6.825 0 00-.069 9.64l35.304 42.862a6.873 6.873 0 002.53 1.644zM656.751 418.482l.055-1.948c-3.624-.1-6.818-.328-9.218-2.082a5.987 5.987 0 01-2.32-4.41 3.422 3.422 0 011.123-2.818c1.594-1.346 4.157-.91 6.026-.053l1.612.739-3.09-22.583-1.93.264 2.628 19.21c-2.539-.747-4.892-.425-6.503.936a5.328 5.328 0 00-1.812 4.374 7.92 7.92 0 003.117 5.914c3.084 2.254 7.092 2.366 10.312 2.457z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#2f2e41"
        d="M326.6 218.806H337.089V220.75400000000002H326.6z"
      ></path>
      <path
        fill="#2f2e41"
        d="M359.709 218.806H370.198V220.75400000000002H359.709z"
      ></path>
      <path
        fill="#2f2e41"
        d="M609.75 656.699l56.852-163.86c-1.835-2.481-3.066-6.972-3.07-12.117v-4.359c.005-7.82 2.836-14.156 6.33-14.165h21.425c3.494.009 6.325 6.345 6.33 14.165v4.359c-.004 5.667-1.495 10.542-3.644 12.807l28.861 109.17a6.316 6.316 0 01-5.482 9.495l-102.12 54a6.33 6.33 0 01-5.483-9.495z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#fff"
        d="M505.504 452.917a43.215 43.215 0 01-20.418-25.655 133.444 133.444 0 01-122.788-13.679c-61.056-41.074-77.312-124.164-36.238-185.22s124.165-77.311 185.22-36.238c60.904 40.973 77.23 123.751 36.544 184.764a43.725 43.725 0 0120.898 26.368 43.507 43.507 0 01-30.157 53.631 43.245 43.245 0 01-33.061-3.97z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#f0f0f0"
        d="M510.36 441.855a32.29 32.29 0 01-14.063-19.046 10.858 10.858 0 00-14.426-7.176l-.661.26a121.454 121.454 0 1156.352-45.246l-.395.587a10.861 10.861 0 003.892 15.641 32.506 32.506 0 0116.111 19.633 31.515 31.515 0 01-4.827 26.984 30.983 30.983 0 01-14.296 10.949 31.861 31.861 0 01-11.253 2.066 31.438 31.438 0 01-16.435-4.652z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#3f3d56"
        d="M505.014 453.789a45.45 45.45 0 01-4.394-2.815c-15.154-10.993-33.032-16.085-50.34-14.341a134.744 134.744 0 01-88.54-22.22c-61.514-41.382-77.892-125.095-36.51-186.608 41.383-61.514 125.095-77.892 186.61-36.51a134.687 134.687 0 0149.816 161.459 43.588 43.588 0 003.066 38.926 44.488 44.488 0 01-25.544 66.073 44.78 44.78 0 01-12.416 1.768 44.488 44.488 0 01-21.748-5.732zM327.72 229.479a131.454 131.454 0 00157.147 195.722l1.545-.608.448 1.6a41.507 41.507 0 0079.936-22.396 41.707 41.707 0 00-20.4-25.407l-1.463-.783.926-1.378a131.454 131.454 0 00-218.14-146.75z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <circle cx="189.248" cy="134.51" r="15.575" fill="#6c63ff"></circle>
      <circle cx="136.108" cy="134.51" r="15.575" fill="#6c63ff"></circle>
      <circle cx="82.968" cy="134.51" r="15.575" fill="#6c63ff"></circle>
      <path
        fill="#2f2e41"
        d="M705.325 376.39c-1.65-3.642-5.513-5.153-9.316-5.314-4.88-.207-9.377 1.89-13.792 3.704-4.56 1.872-9.228 3.4-14.222 3.015a26.035 26.035 0 01-12.398-4.29 24.024 24.024 0 01-10.832-22.213c.8-8.622 5.839-16.895 13.865-20.544a25.766 25.766 0 0127.823 4.369l-2.56 1.06c-1.066-10.64 4.72-20.76 12.84-27.253 8.564-6.85 19.519-10.81 30.258-12.568A67.194 67.194 0 01760 298.84a65.418 65.418 0 0123.637 14.703 67.582 67.582 0 0120.457 52.58 74.776 74.776 0 01-8.038 29.494 77.007 77.007 0 01-18.815 23.43c-15.425 13.144-34.541 20.908-53.377 27.773q-3.515 1.281-7.041 2.533c-1.822.65-2.606-2.248-.798-2.893 18.172-6.48 36.67-13.214 52.537-24.447a81.26 81.26 0 0020.205-20.007 71.583 71.583 0 0011.251-27.17 64.457 64.457 0 00-33.506-69.947 60.611 60.611 0 00-29-6.518 70.737 70.737 0 00-31.33 7.954c-8.977 4.747-16.797 12.346-18.94 22.6a23.783 23.783 0 00-.35 7.252c.138 1.372-1.69 1.873-2.56 1.06a22.74 22.74 0 00-23.074-4.354c-7.7 2.854-12.814 10.528-13.509 18.582a21.153 21.153 0 0010.554 20.198 22.577 22.577 0 0011.616 3.196c4.775-.045 9.175-2.021 13.514-3.805 4.637-1.905 9.342-3.493 14.416-2.807 4.166.564 8.263 2.649 10.067 6.63.79 1.745-1.795 3.272-2.59 1.514z"
        transform="translate(-302.377 -168.442)"
      ></path>
      <path
        fill="#3f3d56"
        d="M449.138 731.558h447.294a1.19 1.19 0 000-2.381H449.138a1.19 1.19 0 000 2.381z"
        transform="translate(-302.377 -168.442)"
      ></path>
    </svg>
  )
}

export default NoReviewIcon;